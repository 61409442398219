import styles from "./ManageStudent.module.css";
import FloatingInput from "../UI/FloatingInput";
import { useMemo, useState } from "react";
import validator from "validator";
import Select from "react-select";
import { Alert, CLASS_ALIAS, DIVISION_ALIAS } from "../../config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    add_bulk_student,
    get_class_list,
    get_division_list,
    queryClient,
} from "../../util/http";

const StudentInfoForm = ({ reset }) => {
    const [numberOfStudents, setNumberOfStudents] = useState({
        value: "",
        isValid: undefined,
    });
    const [prefix, setPrefix] = useState({ value: "", isValid: undefined });
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [addButtonMouseOver, setAddButtonMouseOver] = useState(false);
    // const divisionList = useRef(null);

    const { data: classes } = useQuery({
        queryKey: ["classes"],
        queryFn: get_class_list,
    });

    const classOptions = useMemo(
        () =>
            classes?.data?.data?.list
                ? classes.data.data.list.map((item) => ({
                      value: item.id,
                      label: item.name,
                  }))
                : [],
        [classes]
    );

    const class_id = selectedClass?.value;

    const { data: divisions } = useQuery({
        enabled: !!class_id,
        queryKey: ["divisions", { class_id: class_id }],
        queryFn: get_division_list.bind(null, {
            class_id: class_id,
        }),
    });

    const divisionOptions = useMemo(
        () =>
            divisions?.data?.data?.list
                ? divisions.data.data.list.map((item) => ({
                      value: item.id,
                      label: item.name,
                  }))
                : [],
        [divisions]
    );

    const division_id = selectedDivision?.value;

    // console.log("Classes", classOptions);
    // console.log("Divisions", divisionOptions);

    const { mutate, isPending, isPaused } = useMutation({
        mutationFn: add_bulk_student,
        onError: (error) => {
            Alert.fire({
                title: "Failed to create students",
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
        },
        onSuccess: (response) => {
            const { prefix, no_of_student } = JSON.parse(response.config.data);
            Alert.fire({
                title: "Successfully crafted student accounts 🌟",
                text: `Generated ${no_of_student} student account(s) with prefix '${prefix}'.`,
                icon: "success",
            });
            console.log(response);
            reset();
            queryClient.invalidateQueries({ queryKey: ["students"] });
        },
    });

    const formSubmitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
            !numberOfStudents.isValid ||
            !prefix.isValid ||
            !class_id ||
            !division_id
        )
            return;

        const payload = {
            no_of_student: parseInt(numberOfStudents.value),
            prefix: prefix.value,
            class_id: class_id,
            division_id: division_id,
        };
        console.log(payload);
        mutate(payload);
    };

    const prefixChangeHandler = (e) => {
        const value = e.target.value;
        const isValid =
            validator.isLength(value, { min: 3, max: 10 }) &&
            validator.isAlphanumeric(value, "en-US", {
                ignore: "_",
            }) &&
            value[0] !== "_";
        setPrefix({ value: value, isValid: isValid });
    };

    const numberOfStudentsChangeHandler = (e) => {
        console.log(e.target.value);
        setNumberOfStudents({
            value: e.target.value,
            isValid: validator.isInt(e.target.value, { min: 1, max: 300 }),
        });
    };

    const classSelectChangeHandler = (newValue) => {
        setSelectedClass(newValue);
        setSelectedDivision(null);
    };

    const divisionSelectChangeHandler = (newValue) => {
        setSelectedDivision(newValue);
    };

    return (
        <div className={`card card-bg-1 rounded-3 border-2 my-4`}>
            <div className="card-body">
                <h4 className="card-title text-primary ">Create Students</h4>
                <form onSubmit={formSubmitHandler}>
                    <div className="row">
                        <div className="col-6 col-md-3 mb-3">
                            <Select
                                name="class"
                                options={classOptions}
                                value={selectedClass}
                                classNamePrefix="react-select-bootstrap"
                                placeholder={CLASS_ALIAS}
                                onChange={classSelectChangeHandler}
                            />
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                            <Select
                                name="division"
                                options={divisionOptions}
                                value={selectedDivision}
                                classNamePrefix="react-select-bootstrap"
                                placeholder={DIVISION_ALIAS}
                                onChange={divisionSelectChangeHandler}
                            />
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                            <FloatingInput
                                label="No. of students"
                                type="number"
                                id="studentInfoNumberOfStudents"
                                value={numberOfStudents.value}
                                onChange={numberOfStudentsChangeHandler}
                                valid={numberOfStudents.isValid}
                            />
                        </div>
                        <div className="col-6 col-md-3 mb-3">
                            <FloatingInput
                                label="Username prefix"
                                type="text"
                                id="studentInfoPrefix"
                                value={prefix.value}
                                onChange={prefixChangeHandler}
                                valid={prefix.isValid}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <button
                                className={`btn btn-secondary me-2 ${styles.button}`}
                                onClick={reset}
                                type="reset"
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                className={`btn btn-primary ${styles.button}`}
                                onMouseEnter={(e) =>
                                    setAddButtonMouseOver(true)
                                }
                                onMouseLeave={(e) =>
                                    setAddButtonMouseOver(false)
                                }
                                disabled={isPending}
                            >
                                {!isPending ? (
                                    <>
                                        <i
                                            className={`fa-solid fa-plus ${
                                                addButtonMouseOver && "fa-beat"
                                            }`}
                                            style={{
                                                verticalAlign: "-0.06rem",
                                            }}
                                        ></i>{" "}
                                        Create students
                                    </>
                                ) : !isPaused ? (
                                    "Adding..."
                                ) : (
                                    "Offline"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StudentInfoForm;
